import { Card, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import InfiniteCarousel from 'react-leaf-carousel';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: '40px', 
      paddingBottom: '30px'
    },
    card: {
        maxWidth: 345,
        borderRadius: 0
        
    },
    media: {
        height: 150
    },
  }));

function Services() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={4}>
                    <Typography>
                        <h2>OUR SERVICES</h2>
                        <p>
                        Globally known for our ability to handle every last detail of our customers’ particular logistics and forwarding needs, our Special Services team takes care of all your logistics.
                        </p>
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <InfiniteCarousel
                        breakpoints={[
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            },
                        },
                        ]}
                        arrows={true}
                        dots={false}
                        showSides={false}
                        sidesOpacity={.5}
                        sideSize={.1}
                        slidesToScroll={1}
                        slidesToShow={3}
                        scrollOnDevice={true}
                        slidesSpacing={0}
                        autoCycle={false}
                    >
                        <Card className={classes.card}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                className={classes.media}
                                image="main/1.jpeg"
                                title="Paella dish"
                            />
                        </Card>
                        <Card className={classes.card}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                className={classes.media}
                                image="main/2.jpeg"
                                title="Paella dish"
                            />
                        </Card>
                        <Card className={classes.card}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                className={classes.media}
                                image="main/6.jpeg"
                                title="Paella dish"
                            />
                        </Card>
                        <Card className={classes.card}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                className={classes.media}
                                image="main/7.jpeg"
                                title="Paella dish"
                            />
                        </Card><Card className={classes.card}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                className={classes.media}
                                image="main/8.jpeg"
                                title="Paella dish"
                            />
                        </Card>
                        <Card className={classes.card}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                className={classes.media}
                                image="main/9.jpeg"
                                title="Paella dish"
                            />
                        </Card>
                        <Card className={classes.card}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                className={classes.media}
                                image="main/10.jpeg"
                                title="Paella dish"
                            />
                        </Card>
                        <Card className={classes.card}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                className={classes.media}
                                image="main/11.jpeg"
                                title="Paella dish"
                            />
                        </Card>
                    </InfiniteCarousel>
                </Grid>
            </Grid>
        </div>
    )
}

export default Services
