import { Container, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import PageImage from '../page-image/PageImage';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme) => ({
    clientsPagePic: {

    },
    clientsPageContents: {
        marginTop: '60px',
        marginBottom: '30px'
    },
    clientsPageContent: {
        marginTop: '30px'
    },
    clientsPageContentSeparator: {
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: '#54af36',
        width: '10%'
    }
  }));

function ClientsPage() {
    const classes = useStyles();
    
    return (
        <>
            <div className={classes.clientsPagePic}>
                <PageImage imgUrl="main/2.jpeg" imgTitle="Some Image" />
            </div>
            <Container maxWidth="md">
                <div className={classes.clientsPageContents}>
                    <div className={classes.clientsPageContent}>
                        <h3>OUR CURRENT CLIENTS</h3>
                        <div className={classes.clientsPageContentSeparator}>
                            <Divider />
                        </div>
                        <Typography>
                            This is also been evident as we have the everlasting business relationship with a number of oil and clearing and forwarding companies associated with us namely
                            <List>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="NATIONAL OIL CORPORATION OF KENYA" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="BOLLORE AFRICA LOGISTICS" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="SPEDAG INTERFREIGHT KENYA LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="DHL GLOBAL FORWARDING (K) LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="DHL GLOBAL FORWARDING (UG) LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="DHL SUPPLY CHAIN (K) LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="NEW KENYA COOPERATIVE CREAMERIES LIMITED (NKCC)" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="GREATLAKES FREIGHT LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="BOC GAS KENYA LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="AMI WORLDWIDE LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="KENFREIGHT EA LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="CHAI TRADING CO LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="PREMIUM ENERGY LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="GALANA OIL KENYA LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="PETRO OIL KENYA LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="LEXO ENERGY LTD" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="JAGUAR PETROLEUM LIMITED ETC" />
                                </ListItem>
                            </List>
                        </Typography>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ClientsPage
