import { Drawer } from '@material-ui/core'
import React, { useState } from 'react'

function ContactDrawer({open}) {
    const [show, setShow] = useState(open)

    const toggleDrawer = (show) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setShow(show)
    };

    return (
        <div>
            <Drawer anchor="right" open={show} onClose={toggleDrawer(false)}>
                <h1>Contact Us</h1>
            </Drawer>
        </div>
    )
}

export default ContactDrawer
