import { Card, CardMedia, Container, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom';
import NearMeIcon from '@material-ui/icons/NearMe';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SocialFollow from '../social-follow/SocialFollow';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: '26px', 
      paddingBottom: '26px',
      backgroundColor: '#7eaddd',
      display: 'flex',
      flexGrow: 1,
    //   alignItems: 'center',
    //   justifyContent: 'center',
    },
    logo: {
        backgroundColor: '#7eaddd',
        maxWidth: '166px',
        height: '104px',
        // height: '48px',
        borderRadius: 0
    }, 
    browse: {
        display: 'flex',
        color: 'black',
        textDecoration: '',
        flexDirection: 'column'
    },
    link: {
        color: 'black',
        padding: '10px'
    },
    openingHours: {
        display: 'flex',
        flexDirection: 'column'
    },
    footerBottom: {
        backgroundColor: '#3f51b5',
    },
    footerBottomDown: {
        display: 'flex',
        justifyContent: 'stretch',
        padding: '20px 0'
    }
  }))

function Footer() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                
                <Container maxWidth="md">
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Grid item xs={12}>
                                <Card className={classes.logo}>
                                    <CardMedia
                                        className={classes.logo}
                                        image="/main/logo2.jpeg"
                                        title="Paella dish"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <SocialFollow />
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.browse}>
                            <h5><b>Quick Links</b></h5>
                            <Link to="" className={classes.link}>Home</Link>
                            <Link to="/about" className={classes.link}>About</Link>
                            <Link to="/services" className={classes.link}>Services</Link>
                            <Link to="/clients" className={classes.link}>Clients</Link>
                            <Link to="/contact" className={classes.link}>Contact Us</Link>
                        </Grid>
                        <Grid item xs={5}>
                            <h5>Get In Touch</h5>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <NearMeIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="A.A. Transporters Limited." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <PhoneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="+254706595449, +2540731440440" />
                                </ListItem>
                                <ListItem button component="a" href="mailto:info@aatransportersltd.com">
                                    <ListItemIcon>
                                        <EmailIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="info@aatransportersltd.com" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ScheduleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <>
                                            <Typography>
                                                <div className={classes.openingHours}>
                                                    <p>Mon - Fri: 8am - 5:30pm</p>
                                                    <p>Sat: 8am - 12:30pm</p>
                                                    <p>Sun: Closed</p>
                                                </div>
                                            </Typography>
                                        </>
                                    } />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.footerBottom}>
                <Container maxWidth="md">
                    <div className={classes.footerBottomDown}>
                        <div></div>
                        <div>© {new Date().getFullYear()} A.A. Transporters Limited</div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Footer
