import { Container } from '@material-ui/core'
import React from 'react'
import PageImage from '../page-image/PageImage'
import Clients from '../clients/Clients'
import CompanyHistory from '../history/CompanyHistory'
import Services from '../services/Services'

function Home() {
    return (
        <div>
            <div className="about__pic">
                <PageImage imgUrl="main/4.jpeg" imgTitle="A.A TRANSPORTERS LIMITED" />
            </div>
            <Container maxWidth="md">
                <CompanyHistory />
                <Services />
                <Clients />
            </Container>
        </div>
    )
}

export default Home
