import { Container, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PageImage from '../page-image/PageImage'

const useStyles = makeStyles((theme) => ({
    servicePagePic: {

    },
    servicePageContents: {
        marginTop: '60px',
        marginBottom: '30px'
    },
    servicePageContent: {
        marginTop: '30px'
    },
    servicePageContentSeparator: {
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: '#54af36',
        width: '10%'
    }
  }));
  
function ServicesPage() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.servicePagePic}>
                <PageImage imgUrl="main/3.jpeg" imgTitle="Some Image" />
            </div>
            <Container maxWidth="md">
                <div className={classes.servicePageContents}>
                    <div className={classes.servicePageContent}>
                        <h3>BRIEF SUMMARY OF SERVICES OFFERED</h3>
                        <div className={classes.servicePageContentSeparator}>
                            <Divider />
                        </div>
                        <Typography>
                            Transport is the core service of our company. The following are types of transport services that we offer to different destinations in East Africa and far in the Sub-Saharan region.
                            <List>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Containerized cargo and loose cargo" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Low loader services" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="White oils" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Black oils" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="LPG bulk" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Bitumen" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="LPG bulk tanker" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="LPG cylinders" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Lubes packages" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Lubes bulk" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Packed milk" />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Milk-run products i.e Dry goods" />
                                </ListItem>
                            </List>
                        </Typography>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ServicesPage
