import { Box, Card, CardMedia, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import PageImage from './page-image/PageImage';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    achievementsPagePic: {

    },
    achievementsPageContents: {
        marginTop: '60px',
        marginBottom: '30px'
    },
    achievementsPageContent: {
        marginTop: '30px'
    },
    achievementsPageContentSeparator: {
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: '#54af36',
        width: '10%'
    }
  }));
  
function AchievementsPage() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.achievementsPagePic}>
                <PageImage imgUrl="/main/trophy-1.jpeg" imgTitle="Some Image" />
            </div>
            <Container maxWidth="md">
                <div className={classes.achievementsPageContents}>
                    <div className={classes.achievementsPageContent}>
                        <h3>Our Achievements & Awards</h3>
                        <div className={classes.achievementsPageContentSeparator}>
                            <Divider />
                        </div>
                        <Typography>
                            <p>
                            A.A. Transporters Ltd was awarded the 12th International Transport Trophy and a Certificate on 16th November 2009 for its Distinguished Service Provision in goods transportation. The company also received the Golden Badge for “Global Quality Management” as special work recognition.
                            </p>
                            <br/>
                            <p>
                            The 12th International Transport Award (New Millennium Award) ceremony for 2009 took place at Hotel Melia Castilla, Madrid, Spain, organized and Hosted by the Trade Leaders’ club. The ceremony was attended by 30 participant companies from all over the world and local authorities related to the sector and diplomatic representatives accredited in Madrid Spain.
                            </p>
                            <br/>
                            <p>
                            A.A Transporters Ltd was locally awarded a certificate in 2007 in recognition of road safety by Association of Kenya Insurers. The company was also awarded The Majestic Five continent award & Top Quality Satisfaction (TQCS) By Other ways in Italy-Rome in 2011. Additionally, we were awarded by Association of Kenya Insurers in 2012 for positive contribution to road safety.
                            </p>
                            <br/>
                            <p>
                                We have also been awarded by the European Society for Quality Research a certificate and Trophy on December 2 , 2012 in Brussels , Belgium in recognition of extraordinary achievements in quality management. In the year 2013 we were awarded a certificate by Total Kenya Ltd as the Best Transporter  for our outstanding performance and quality of our transport service.
                            </p>
                            <br/>
                            <p>
                            In the year 2015 and 2016, we were awarded by National Road Safety Agency on excellence of liquified oil transporters for the year 2015 and 2016 respectively.
                            </p>
                        </Typography>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default AchievementsPage
