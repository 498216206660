import React from 'react'
import './CompanyHistory.css'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: '40px', 
      paddingBottom: '30px'
    },
  }));

function CompanyHistory() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper elevation={0}>
                <Typography component="div" >
                    <h1 className="text-center">COMPANY HISTORY</h1>
                    <p>A.A TRANSPORTERS LIMITED is a transport company based in Mombasa,
                    Kenya with is offices along Kismayu Road, Shimanzi area. A.A TRANSPORTERS LIMITED
                    is a private owned company established in 1960. We have a fleet of around 120 trucks
                    comprising of Mercedes Benz, Renault, Daf, Man, Scania, Mitsubushi,
                    AND ERF plying local routes, within Kenya and over the border routes transporting
                both containerized cargo, loose cargo and oil product.</p>
                    <p>The management is focused in providing safe, cost effective,
                    timely and efficient transport services through our experienced,
                    dedicated and trained workforce who work hand in hand in providing
                services to our customers.</p>
                </Typography>
            </Paper>
        </div>
    )
}

export default CompanyHistory
