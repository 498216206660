import { Card, CardMedia, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({

    root: {
      paddingTop: '40px', 
      paddingBottom: '30px'
    },
    card: {
        maxWidth: '100%',
        borderRadius: 0
        
    },
    media: {
        height: 700
    },
  }));

function PageImage({ imgUrl, imgTitle }) {
    const classes = useStyles();
    
    return (
        <div >
            <Card className={classes.card}>
                <CardMedia
                    component="img"
                    alt={imgTitle}
                    className={classes.media}
                    image={imgUrl}
                    title={imgTitle}
                />
            </Card>
        </div>
    )
}

export default PageImage
