import { Avatar, Container, Divider, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PageImage from '../page-image/PageImage'
import './AboutPage.css'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  }));
  
function AboutPage() {
    const classes = useStyles();

    return (
        <>
            <div className="about__pic">
                <PageImage imgUrl="main/about.jpeg" imgTitle="Some Image" />
            </div>
            <Container maxWidth="md">
                <div className="about__contents">
                    <div className="about__content">
                        <h3>Our Mission</h3>
                        <div className="about__content--separator">
                            <Divider />
                        </div>
                        <Typography>
                        Our mission is to constantly exceed customer expectations by providing superior Transport Services and global transportation solutions and logistics services.
        We make this possible by recognizing that no two customers are alike, therefore forming a symbiotic relationship and fulfilling individual needs.
        This unique perspective coupled with a network designed to support worldwide distribution and supply chain management, and a well developed Eastern Africa Infrastructure enables  A.A. Transporters Limited to tailor resources to meet individual requirements.
        We approach every need of our customer with utmost flexibility because we concentrate not only on the processes but also at the end result - proper delivery of the Cargo.
                        </Typography>
                    </div>
                    <div className="about__content">
                        <h3>Our Vision</h3>
                        <div className="about__content--separator"><Divider /></div>
                        <Typography>
                        By providing total solutions to our customers, ensuring stake holder's interest , we intend to be the best in the industry by offering specialized Transport & logistics solution that encompasses high quality innovative services. We would also like to be the market leader and trend setter in the East Africa and Sub Sahara region.
                        </Typography>
                    </div>
                    <div className="about__content">
                        <h3>Our Objectives</h3>
                        <div className="about__content--separator"><Divider /></div>
                        <Typography>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemIcon>
                                        <TrendingUpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Benefit our shareholders" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <TrendingUpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Realize the potential of our clients" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <TrendingUpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Meet our customer requirements" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <TrendingUpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Maximize Transport margins" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <TrendingUpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Safeguard asset integrity" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <TrendingUpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Deliver structural cost reductions" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <TrendingUpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Sustain a robust management system" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <TrendingUpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Deliver continuous sustainable Health, Safety, Security and Environmental excellence" />
                                </ListItem>
                            </List>
                        </Typography>
                    </div>
                    <div className="about__content">
                        <h3>ORGANIZATIONAL STRATEGIES & GOALS</h3>
                        <div className="about__content--separator"><Divider /></div>
                        <Typography>
                        Organizational strategy is the creation, implementation and evaluation of decisions within an organization that enables it to achieve its long-term objectives. Organizational strategy specifies the organization’s mission, vision and objectives and develops policies and plans, often in terms of projects and programs, created to achieve the organization’s objectives. It also allocates resources to implement them.
                        </Typography>
                    </div>
                    <div className="about__content">
                        <h4>Strategies Adopted</h4>
                        <Typography>
                            <List>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Diversification Strategies" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText inset primary="Where we have diversified our operations, we provide different transport services i.e. fuel transportation, container transportation and other loose cargo." />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Organizational Goals" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText 
                                        inset 
                                        primary="Those ends that an organization seeks to achieve by its existence and operation." 
                                        secondary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    <List>
                                                        <ListItem alignItems="flex-start">
                                                            <ListItemIcon>
                                                                <FiberManualRecordIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Short term goals" />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText inset primary="Those ends that an organization seeks to achieve by its existence and operation." />
                                                        </ListItem>

                                                        <ListItem alignItems="flex-start">
                                                            <ListItemIcon>
                                                                <FiberManualRecordIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Long-term goals" />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText inset primary="Are ones that you will achieve over a longer period of time (e.g., one semester, one year, five years, or twenty years)" />
                                                        </ListItem>
                                                    </List>
                                                </Typography>
                                            </>
                                        }/>
                                </ListItem>
                            </List>
                        </Typography>
                    </div>
                    <div className="about__content">
                        <h4>Organizational Goals</h4>
                        <Typography>
                            <List >
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="SHORT TERM GOALS"
                                        secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                <List>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="inherit" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Increase in earnings." />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="inherit" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Corporate Social Responsibility." />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="inherit" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Employees satisfaction." />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="inherit" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Customer satisfaction." />
                                                    </ListItem>
                                                </List>
                                            </Typography>
                                        </React.Fragment>}/>
                                </ListItem>
                                
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <ArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="LONG TERM GOALS"
                                        secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                <List>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="inherit" />
                                                        </ListItemIcon>
                                                        <ListItemText 
                                                            primary="Business expansion." 
                                                            secondary={
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        className={classes.inline}
                                                                        color="textPrimary"
                                                                    >
                                                                        To expand our fleet in order to serve all over East African and Sub Saharan region.
                                                                    </Typography>
                                                                </>
                                                            }/>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="inherit" />
                                                        </ListItemIcon>
                                                        <ListItemText 
                                                            primary="Increase in profitability."
                                                            secondary={
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        className={classes.inline}
                                                                        color="textPrimary"
                                                                    >
                                                                        To expand our services all over East African and sub Saharan region.
                                                                    </Typography>
                                                                </>
                                                            } />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="inherit" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="To be the market leader and trend setter in East Africa and sub Saharan region." />
                                                    </ListItem>
                                                </List>
                                            </Typography>
                                        </React.Fragment>}/>
                                </ListItem>
                            </List>
                        </Typography>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default AboutPage
