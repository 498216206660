import { Button, Container, Grid, Icon, makeStyles, TextField, Typography } from '@material-ui/core'
import React from 'react'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import HouseIcon from '@material-ui/icons/House';
import './ContactPage.css'
import SendIcon from '@material-ui/icons/Send';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: '26px', 
      paddingBottom: '26px',
      display: 'flex',
      flexGrow: 1,
    //   alignItems: 'center',
    //   justifyContent: 'center',
    },
    contacts: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            margin: '15px'
        },
        '& h2': {
            marginBottom: '10px'
        }
    },
    contact: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& *':{
            margin: '5px'
        }
    },
    contactForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > f > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    }
}))

const validationSchema = yup.object({
    visitorName: yup
      .string('Enter your name')
      .min(2, 'Name should be of minimum 2 characters length')
      .required('Name is required'),
    visitorEmail: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    visitorPhone: yup
      .string('Enter your phone number')
      .min(10, 'Phone number should be of minimum 10 characters length')
      .required('Phone number is required'),
    visitorSubject: yup
      .string('Enter email subject')
      .min(3, 'Email subject should be of minimum 3 characters length')
      .required('Email subject is required'),
    visitorMessage: yup
      .string('Enter your message')
      .min(8, 'Message should be of minimum 8 characters length')
      .required('Message is required'),
  });

function ContactPage() {
    const formik = useFormik({
        initialValues: {
            visitorName: '',
            visitorEmail: '',
            visitorPhone: '',
            visitorSubject: '',
            visitorMessage: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios.post(`https://us-central1-aatransporters-app.cloudfunctions.net/sendMail`, values)
                .then(res => {
                    alert(JSON.stringify(res, null, 2));
                })
        },
      });

    const classes = useStyles();
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.9036600781587!2d39.64329431475999!3d-4.0400799970620636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNMKwMDInMjQuMyJTIDM5wrAzOCc0My43IkU!5e0!3m2!1sen!2ske!4v1616593930491!5m2!1sen!2ske" width="100%" height="450" allowfullscreen="" loading="lazy"></iframe>
            <div className={classes.root}>
                <Container maxWidth="md">
                    <Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={6} className={classes.contacts}>
                                <h2>Get In Touch</h2>
                                <div className={classes.contact}>
                                    <PhoneAndroidIcon fontSize="large" />
                                    <h4>Call Us On</h4>
                                    <p>+254706595449</p>
                                </div>
                                <span></span>
                                <div className={classes.contact}>
                                    <HouseIcon fontSize="large" />
                                    <h4>Find Us</h4>
                                    <p>+2540731440440</p>
                                </div>
                            </Grid>
                            <Grid item xs={6} className={classes.contactForm}>
                                <h2>Contact Form</h2>
                                <div className={classes.margin}>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <TextField 
                                                    id="visitorName" 
                                                    name="visitorName" 
                                                    label="Your Name"
                                                    value={formik.values.visitorName}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.visitorName && Boolean(formik.errors.visitorName)}
                                                    helperText={formik.touched.visitorName && formik.errors.visitorName}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField 
                                                    id="visitorEmail" 
                                                    name="visitorEmail" 
                                                    label="Your Email"
                                                    value={formik.values.visitorEmail}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.visitorEmail && Boolean(formik.errors.visitorEmail)}
                                                    helperText={formik.touched.visitorEmail && formik.errors.visitorEmail} 
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField 
                                                    id="visitorPhone" 
                                                    name="visitorPhone" 
                                                    label="Phone Number"
                                                    value={formik.values.visitorPhone}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.visitorPhone && Boolean(formik.errors.visitorPhone)}
                                                    helperText={formik.touched.visitorPhone && formik.errors.visitorPhone}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField 
                                                    id="visitorSubject" 
                                                    name="visitorSubject" 
                                                    label="Subject"
                                                    value={formik.values.visitorSubject}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.visitorSubject && Boolean(formik.errors.visitorSubject)}
                                                    helperText={formik.touched.visitorSubject && formik.errors.visitorSubject} 
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="visitorMessage"
                                                    name="visitorMessage"
                                                    label="Message"
                                                    multiline
                                                    fullWidth
                                                    rows={5}
                                                    rowsMax={10}
                                                    value={value}
                                                    onChange={handleChange}
                                                    variant="filled"
                                                    value={formik.values.visitorMessage}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.visitorMessage && Boolean(formik.errors.visitorMessage)}
                                                    helperText={formik.touched.visitorMessage && formik.errors.visitorMessage}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    className={classes.button}
                                                    endIcon={<SendIcon />}
                                                >
                                                    Send
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </Grid>
                        </Grid>
                    </Typography>
                </Container>
            </div>
        </>
    )
}

export default ContactPage
