export const photos = [
    {
      src: "main/1.jpeg",
      width: 4,
      height: 3
    },
    // {
    //   src: "main/2.jpeg",
    //   width: 1,
    //   height: 1
    // },
    {
      src: "main/3.jpeg",
      width: 4,
      height: 2
    },
    {
      src: "main/4.jpeg",
      width: 4,
      height: 2
    },
    {
      src: "main/5.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "main/6.jpeg",
      width: 3,
      height: 4
    },
    {
      src: "main/7.jpeg",
      width: 3,
      height: 4
    },
    {
      src: "main/8.jpeg",
      width: 2,
      height: 4
    },
    {
      src: "main/9.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "main/10.jpeg",
      width: 2,
      height: 2
    },
    {
      src: "main/11.jpeg",
      width: 4,
      height: 2
    },
    {
      src: "main/12.jpeg",
      width: 4,
      height: 2
    },
    {
      src: "main/13.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "main/14.jpeg",
      width: 4,
      height: 2
    },
    {
      src: "main/15.jpeg",
      width: 4,
      height: 3
    }
  ]  