import './App.css';
import Header from './features/header/Header';
import Footer from './features/footer/Footer';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from './features/home/Home';
import AboutPage from './features/about/AboutPage';
import ServicesPage from './features/services/ServicesPage';
import ClientsPage from './features/clients/ClientsPage';
import AchievementsPage from './features/AchievementsPage';
import ContactPage from './features/contacts/ContactPage';
import GalleryPage from './features/gallery/GalleryPage';

function App() {
  return (
    <Router>
      <div className="app">
        <Switch>
          <Route path="/clients">
            <Header />
            <ClientsPage />
            <Footer />
          </Route>
          <Route path="/services">
            <Header />
            <ServicesPage />
            <Footer />
          </Route>
          <Route path="/about">
            <Header />
            <AboutPage />
            <Footer />
          </Route>
          <Route path="/contact">
            <Header />
            <ContactPage />
            <Footer />
          </Route>
          <Route path="/achievements">
            <Header />
            <AchievementsPage />
            <Footer />
          </Route>
          <Route path="/gallery">
            <Header />
            <GalleryPage />
            <Footer />
          </Route>
          <Route path="/">
            <Header />
            <Home />
            <Footer />
          </Route>
        </Switch>
        
      </div>
    </Router>
  );
}

export default App;
