import React from 'react'
import './Clients.css'

import InfiniteCarousel from 'react-leaf-carousel';
import { Card, CardMedia, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '40px', 
        paddingBottom: '30px'
    },
    card: {
        borderRadius: 0,
        boxShadow: 'none',
        height: '200px'
    },
    media: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
  }));
  
function Clients() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <InfiniteCarousel
                breakpoints={[
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                ]}
                arrows={false}
                dots={false}
                showSides={false}
                sidesOpacity={.5}
                sideSize={.1}
                slidesToScroll={1}
                slidesToShow={4}
                scrollOnDevice={true}
                slidesSpacing={0}
                autoCycle={true}
            >
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/1.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/2.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/5.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/national-oil.jpg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/7.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/8.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/10.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/11.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/12.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/14.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/15.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/16.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/17.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/18.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/19.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/20.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/22.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/23.jpeg"
                        title="Paella dish"
                    />
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        className={classes.media}
                        image="clients/24.jpeg"
                        title="Paella dish"
                    />
                </Card>
            </InfiniteCarousel>
        </div>
    )
}

export default Clients
